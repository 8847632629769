import packageJson from '../../package.json';

export const environment = {
  production: true,
  api: 'https://rentability-test.azurewebsites.net',
  googleMapsKey: 'AIzaSyAXhKeLEZlF5YiorNqu8qTOlennztmIGV4',
  localStorageKey: 'currentUser',
  defaultProfilePhotoUrl: 'https://rentability.xyz/assets/images/profile.jpg',
  errorHandler: 'https://0160da7a3fea41b4ae94630012c56d56@sentry.io/246940',
  stripeKey: 'pk_test_9sMqyCMgqDVJTCpp4yemdm5v',
  hubConnection: '/notify',
  taxAmount: 5.0,
  tinyMceApiKey: 'it40euyuribqq64my7ttc9au79hbdhi5lgbv44lh26wsnlua',
  staticUrl: 'https://rentability.xyz/',
  url: 'https://rentability.xyz',
  home: 'https://rentability.xyz',
  version: packageJson.version,
  googleAppId: '370704219593-3j239kofblqtgggu4fj3210gshlgegav.apps.googleusercontent.com',
  facebookAppId: '190262734864105',
  imageUrl: 'https://rentability-test.azurewebsites.net/api/file/getImage/',
  thumbnailUrl: 'https://rentability-test.azurewebsites.net/api/file/getThumbnail/',
  analytics: 'G-M9N2D6MEV5',
  isHubspotEnabled: true,
  isLoggingEnabled: false,
  appInsights: {
    instrumentationKey: '3f5a298e-6fe0-4413-825a-1c1e2eac9806'
  },
  auth:{
    domain:'rentability-test.us.auth0.com',
    clientId: 'CrPdIrlRj42aUfrqDzSlbYrUjXlCDi9n'
  }

};
